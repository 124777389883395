<template>
  <div>
    <div class="flex items-center justify-center h-screen verify">
      <div class="hidden sm:block w-1/3 bg-cover h-screen" style='background-position: center;background-size: contain;background-repeat: no-repeat;background-image: url(https://kambio.ca/wp-content/themes/kambio_theme/img/cars.svg)'>
        <div class="bg-blue-800 w-full h-screen bg-opacity-20">
        </div>
      </div>
      <div class="sm:w-2/3">
        <div class="p-5 w-4/5 mx-auto text-left font-raleway">
          <h1 class="font-bold text-left font-montserrat text-4xl sm:text-6xl mb-10">
            Your account is verified successfully. You can use your account now
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Verify',

  data() {
    return {
      email: '',
      done: false,
      error: false,
    }
  }
}

</script>
<style scoped>
</style>

