<template>
  <div>
    <div class="flex items-center justify-center h-screen">
      <div class="hidden sm:block w-1/3 bg-cover h-screen" style='background-position: center;background-size: contain;background-repeat: no-repeat;background-image: url(https://kambio.ca/wp-content/themes/kambio_theme/img/cars.svg)'>
        <div class="bg-blue-800 w-full h-screen bg-opacity-20">
        </div>
      </div>
      <div class="sm:w-2/3">
        <div class="p-5 w-4/5 mx-auto text-left font-raleway">
          <h1 v-show="!done" class="font-bold text-left font-montserrat text-4xl sm:text-6xl mb-10">
            Recover Your Password
          </h1>
          <p v-if="errors.length">
            <b class="text-sm text-red-500">Please correct the following error(s):</b>
            <ul>
              <li  class="text-sm text-red-500" v-bind:key="error" v-for="error in errors">{{ error }}</li>
            </ul>
          </p>
          <p v-show="error" class="text-sm text-red-500">An Error Occurred, Please Try Again</p>
          <h1 v-show="done" class="font-bold text-left font-montserrat text-4xl">Your password is changed successfully, you can login with new password now.</h1>
          <form @submit="resetPassword" v-show="!done">
            <div class="my-5">
              <h1 class="text-left font-bold mb-5 font-montserrat">Password</h1>
              <input type="password" v-model="password" class="text-sm outline-none pb-5 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700">
            </div>
            <div class="my-5">
              <h1 class="text-left font-bold mb-5 font-montserrat">Confirm Password</h1>
              <input type="password" v-model="confirmPassword" class="text-sm outline-none pb-5 w-4/5 bg-transparent border-b hover:border-blue-700 focus:border-blue-700">
            </div>

            <button type="submit"  class="bg-green-400 p-5 text-white">
              Reset Password <font-awesome-icon class="ml-3" :icon="['fas', 'arrow-right']" />
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ResetPassword',
  data() {
    return {
      errors: [],
      password: '',
      confirmPassword: '',
      done: false,
      error: false,
    }
  },
  methods: {
    async resetPassword(e) {
      e.preventDefault()
      this.error = false;
      this.errors = [];
      if (!this.password)
        this.errors.push("Password is required!");
      if (!this.confirmPassword)
        this.errors.push("Confirm Password is required!");
      if (this.errors.length > 0)
        return;
      if (this.confirmPassword != this.password)
        this.errors.push("Password and Confirm Password must be identical!");

      const globalRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})", 'g');
      if (!globalRegex.test(this.password)){
        this.errors.push('Password must meet complexity requirements, Password MUST contain at least:');
        this.errors.push('8 characters');
        this.errors.push('one numerical letter');
        this.errors.push('one lowercase letter');
        this.errors.push('one uppercase letter');
        this.errors.push('one special character');
      }
      if (this.errors.length > 0)
        return;
      this.axios.post(`https://api.kambio.ca/auth/reset-password`, {
        code: this.$route.query.code,
        password: this.password,
        passwordConfirmation: this.confirmPassword
      })
          .then(() => {
            this.done = true
          })
          .catch(e => {
            e;
            this.error = true

            console.log(e);
            console.log(JSON.stringify(e))
          })
    }
  },
}
</script>
<style scoped>
</style>
