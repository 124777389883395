import Vue from 'vue'
import VueRouter from 'vue-router'
import ResetPassword from '../views/Home.vue'
import Verify from '../views/Verify.vue'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: ResetPassword
    },
    {
        path: '/verify',
        name: 'verify',
        component: Verify
    }
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})
export default router
